import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import servicesList from '../Data/ServicesData';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import SelectReact from 'react-select';

const AddBooking = ({ onClose }) => {
  const [formData, setFormData] = useState({
    branch: '',
    companyName: '',
    contactPerson: '',
    contactNumber: '',
    email: '',
    date: '',
    services: [], // Updated to handle multiple services
    totalAmount: '',
    selectTerm: '',
    amount: '',
    paymentDate: '',
    closed:'',
    pan: '',
    gst: '',
    notes: '',
    bank:'',
    funddisbursement:''

  });

  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false); // Dialog state for popup
  const [bookingId, setBookingId] = useState(null); // Store booking ID

// Handle input changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};
// Handle multiple services selection
const handleServiceChange = (selectedOptions) => {
  setFormData({
    ...formData,
    services: selectedOptions ? selectedOptions.map(option => option.value) : [], // Map selected options to an array
  });
};


const serviceOptions = servicesList.map((service) => ({
  value: service.value,
  label: service.label,
  isDisabled: service.disabled, // Optional: Handle disabled options
}));

const validate = () => {
  let validationErrors = {};

  // Validation logic (unchanged)
  if (!formData.branch) validationErrors.branch = "Branch is required";
  // if (!formData.companyName) validationErrors.companyName = "Company Name is required";
  if (!formData.contactPerson) validationErrors.contactPerson = "Contact Person is required";
  const contactNumberRegex = /^\d{10}$/;
  if (!formData.contactNumber || !contactNumberRegex.test(formData.contactNumber)) {
    validationErrors.contactNumber = "Valid Contact Number is required (10 digits, no spaces)";
  }
  if (!formData.email) validationErrors.email = "Email is required";
  if (!formData.date) validationErrors.date = "Date is required";
  if (!formData.totalAmount || isNaN(formData.totalAmount)) {
    validationErrors.totalAmount = "Valid Total Amount is required";
  }
  if (!formData.selectTerm) validationErrors.selectTerm = "Select Term is required";
  if (!formData.amount || isNaN(formData.amount)) {
    validationErrors.amount = "Valid Amount is required";
  }
  if (Number(formData.amount) > Number(formData.totalAmount)) {
    validationErrors.amount = "Received Amount cannot be greater than Total Amount";
  }
  if (!formData.paymentDate) validationErrors.paymentDate = "Payment Date is required";
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  if (formData.pan && !panRegex.test(formData.pan)) {
    validationErrors.pan = "Valid PAN is required (10 characters, no spaces, no special characters)";
  }
  setErrors(validationErrors);
  return Object.keys(validationErrors).length === 0;
};

// Handle form submission
const handleSubmit = (e) => {
  e.preventDefault();

  if (validate()) {
    const userSession = JSON.parse(localStorage.getItem('userSession'));

    if (userSession) {
      const dataToSubmit = {
        user_id: userSession.user_id,
        bdm:userSession.name,
        branch_name: formData.branch,
        company_name: formData.companyName.toUpperCase(),
        contact_person: formData.contactPerson,
        email: formData.email,
        contact_no: Number(formData.contactNumber),
        services: formData.services,
        total_amount: Number(formData.totalAmount),
        closed_by:formData.closed ? formData.closed : "",
        term_1: formData.selectTerm === "Term 1" ? Number(formData.amount) : null,
        term_2: formData.selectTerm === "Term 2" ? Number(formData.amount) : null,
        term_3: formData.selectTerm === "Term 3" ? Number(formData.amount):null,
        term_1_payment_date: formData.selectTerm === "Term 1" ? formData.paymentDate:null,
        term_2_payment_date:formData.selectTerm === "Term 2" ? formData.paymentDate: null,
        term_3_payment_date:formData.selectTerm === "Term 3" ? formData.paymentDate: null,
        pan: formData.pan,
        gst: formData.gst ? formData.gst : "N/A",
        remark: formData.notes,
        date: formData.date,
        bank: formData.bank,
        status:'Pending',
        funddisbursement:formData.funddisbursement
      };
// console.log(dataToSubmit);

      const apiEndpoint = 'https://crm-testings.onrender.com/booking/addbooking';
      // const apiEndpoint = 'http://localhost:5353/booking/addbooking';

      fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error creating booking');
          }
          return response.json();
        })
        .then((res) => {
          const bookingId = res.booking_id.toUpperCase(); // Use booking_id from the backend response
          setBookingId(bookingId); // Set booking ID to show in the popup
          setOpenDialog(true); // Open the dialog popup
          enqueueSnackbar(`Booking created successfully!`, { variant: 'success' });

          // Reset the form after successful submission
          setFormData({
            branch: '',
            companyName: '',
            contactPerson: '',
            contactNumber: '',
            email: '',
            date: '',
            services: [], // Reset services as an array
            totalAmount: '',
            closed:'',
            selectTerm: '',
            amount: '',
            paymentDate: '',
            pan: '',
            gst: '',
            notes: '',
            bank: '',
            funddisbursement:''
          });

          if (onClose) onClose(); // Close the form after submission
        })
        .catch((error) => {
          console.error('Error:', error);
          enqueueSnackbar(`Error creating booking: ${error.message}`, { variant: 'error' });
        });
    } else {
      enqueueSnackbar('User session not found. Please log in again.', { variant: 'warning' });
    }
  }
};

const handleWheel = (event) => {
  // Prevent default behavior
  event.preventDefault();
};


  const handleDialogClose = () => {
    setOpenDialog(false);
    if (onClose) onClose();
  };

  return (
    <Box>
      <Typography variant="h5" component="h2" sx={{ marginBottom: 2 }}>
        Create New Booking
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Branch</InputLabel>
              <Select
                name="branch"
                value={formData.branch}
                onChange={handleChange}
              >
                <MenuItem value="">Select branch</MenuItem>
                <MenuItem value="1206 A">1206 A</MenuItem>
                <MenuItem value="808">808</MenuItem>
                <MenuItem value="404">404</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              placeholder="Enter company name"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact Person Name"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              placeholder="Enter contact person name"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Contact Number"
              name="contactNumber"
              type="number"
              value={formData.contactNumber}
              onWheel={handleWheel}
              onChange={handleChange}
              placeholder="Enter contact number"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email ID"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter email ID"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Booking Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">Services</Typography>
            <SelectReact
              options={serviceOptions}
              value={serviceOptions.filter(option => formData.services.includes(option.value))}
              onChange={handleServiceChange}
              isMulti
              placeholder="Select Services"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Total Amount"
              name="totalAmount"
              type="number"
              value={formData.totalAmount}
              onWheel={handleWheel}
              onChange={handleChange}
              placeholder="Enter total amount"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Select Term</InputLabel>
              <Select
                name="selectTerm"
                value={formData.selectTerm}
                onChange={handleChange}
              >
                <MenuItem value="">Select Term</MenuItem>
                <MenuItem value="Term 1">Term 1</MenuItem>
                <MenuItem value="Term 2">Term 2</MenuItem>
                <MenuItem value="Term 3">Term 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Received Amount"
              name="amount"
              type="number"
              value={formData.amount}
              onWheel={handleWheel}
              onChange={handleChange}
              placeholder="Enter received amount"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Closed By"
              name="closed"
              value={formData.closed}
              onChange={handleChange}
              placeholder="Lead closed by"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Payment Date"
              name="paymentDate"
              type="date"
              value={formData.paymentDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="PAN Number"
              name="pan"
              value={formData.pan}
              onChange={handleChange}
              placeholder="Enter PAN"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="GST Number"
              name="gst"
              value={formData.gst}
              onChange={handleChange}
              placeholder="Enter GST"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Payment Mode</InputLabel>
              <Select
                name="bank"
                value={formData.bank}
                onChange={handleChange}
              >
                <MenuItem value="">Select Payment Mode</MenuItem>
                <MenuItem value="Kotak Mahindra Bank">Kotak Mahindra Bank</MenuItem>
                <MenuItem value="HDFC Bank">HDFC Bank</MenuItem>
                <MenuItem value="Razorpay">Razorpay</MenuItem>
                <MenuItem value="HDFC Gateway">HDFC Gateway</MenuItem>
                <MenuItem value="Cash">Cash</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="After Fund Disbursement"
              name="funddisbursement"
              value={formData.funddisbursement}
              onChange={handleChange}
              placeholder="Enter percentage"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Enter any notes"
              multiline
              rows={3}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Dialog for Popup */}
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Booking Created Successfully!</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Your Booking ID is: {bookingId}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddBooking;



