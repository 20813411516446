import React, { useState, useEffect, useCallback } from "react";
import {
  CssVarsProvider,
  extendTheme,
  useColorScheme,
} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { useNavigate } from "react-router-dom";

const API_URL = "https://crm-testings.onrender.com";

const customTheme = extendTheme({ defaultColorScheme: "dark" });

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      onClick={() => setMode(mode === "light" ? "dark" : "light")}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

const LoginSignup = ({ onLoginSuccess }) => {
  const [isActive, setIsActive] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "", name: "" });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }

    if (isActive && !formData.name) {
      errors.name = "Name is required for registration";
    }

    return errors;
  };

  const handleRegisterClick = () => {
    setIsActive(true);
    setFormErrors({});
  };

  const handleLoginClick = () => {
    setIsActive(false);
    setFormErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      isActive ? await registerUser() : await loginUser();
    } else {
      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const registerUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (!response.ok) {
        setFormErrors({ email: data.message || "Registration failed" });
      } else {
        setFormData({ email: "", password: "", name: "" });
      }
    } catch (error) {
      setFormErrors({ email: "An error occurred. Please try again." });
    }
    setLoading(false);
  };

  const loginUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/user/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: formData.email, password: formData.password }),
      });

      const data = await response.json();
      if (!response.ok) {
        setFormErrors({ login: data.message || "Login failed" });
      } else {
        const { token, user } = data;
        localStorage.setItem(
          "userSession",
          JSON.stringify({
            token,
            user_id: user._id,
            name: user.name,
            email: user.email,
            user_role: user.user_role,
            loginTime: Date.now(),
          })
        );
        localStorage.setItem("isAuthenticated", "true");
        onLoginSuccess();
        navigate("/dashboard");
      }
    } catch (error) {
      setFormErrors({ login: "An error occurred. Please try again." });
    }
    setLoading(false);
  };

  return (
    <CssVarsProvider theme={customTheme}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.level1",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            backdropFilter: "blur(12px)",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: "sm",
            boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography level="h4">{isActive ? "Sign Up" : "Sign In"}</Typography>
            <ColorSchemeToggle />
          </Box>
          <form onSubmit={handleSubmit}>
            {isActive && (
              <FormControl required>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                />
              </FormControl>
            )}
            <FormControl required>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
              />
            </FormControl>
            <FormControl required>
              <FormLabel>Password</FormLabel>
              <Input
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Your Password"
              />
            </FormControl>
            {formErrors.login && (
              <Typography color="danger" level="body2">
                {formErrors.login}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? "Loading..." : isActive ? "Register" : "Sign In"}
            </Button>
          </form>
          <Divider sx={{ my: 2 }}>or</Divider>
          <Typography>
            {isActive ? "Already have an account?" : "Don't have an account?"}{" "}
            <Button onClick={isActive ? handleLoginClick : handleRegisterClick} variant="text">
              {isActive ? "Sign In" : "Sign Up"}
            </Button>
          </Typography>
        </Box>
      </Box>
    </CssVarsProvider>
  );
};

export default LoginSignup;
